export const PaymentColors = {
  SUCCESS: '#29F3B6',
  SUCCESS_SHADOW: 'rgb(41	243	182 / 30%)',
  SUCCESS_ADDITIONAL_SHADOW: 'rgb(41 243 182 / 15%)',
  FAIL: '#E32263',
  FAIL_SHADOW: 'rgb(227 34 99 / 30%)',
  FAIL_ADDITIONAL_SHADOW: 'rgb(227 34 99 / 15%)',
  PAYMENT_GRADIENT_LIGHTEN: ['rgba(142, 96, 238) 29.49%', 'rgba(221, 34, 173) 70.51%'],
  PAYMENT_GRADIENT_LIGHTEN_REVERSED: ['rgba(221, 34, 173) 29.49%', 'rgba(142, 96, 238) 70.51%'],
  PAYMENT_GRADIENT: ['rgba(142, 96, 238, 0.12) 29.49%', 'rgba(221, 34, 173, 0.12) 70.51%'],
  WAIT: '#8B8C96',
  WAIT_SHADOW: 'rgb(139 140 150 / 30%)',
  WAIT_ADDITIONAL_SHADOW: 'rgb(139 140 150 / 15%)',
};
